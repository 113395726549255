<template>
  <div>
    <div class="d-flex mx-5">
      <div class="mt-2 mb-1 align-center gap border-radius d-flex flex-wrap flex-grow-1">
        <slot name="prepend" />
        <div
          class="save"
          v-if="save && showSave"
        >
          <v-badge
            avatar
            offset-y=17
            offset-x=15
            color="secondary"
            :value="computedWarningSave"
          >
            <template v-slot:badge>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    size=18
                    style="height: 100%"
                    v-on="on"
                    v-bind="attrs"
                  >{{$icon('i.ExclamationThick')}}</v-icon>
                </template>
                <span>{{computedWarningSave}}</span>
              </v-tooltip>
            </template>
            <v-btn
              :disabled="!tab.canSave || tab.isPristine"
              fab
              small
              color="success"
              @click.stop="doSave()"
              :loading="saveInProgress"
            >
              <icon-tooltip
                icon-name="i.Checked"
                ref-icon="check"
                :tooltip-text="$t('t.Validate')"
              />
            </v-btn>
          </v-badge>
        </div>
        <div
          class="undo"
          v-if="!tab.isNew && revert && showCancel"
        >
          <v-btn
            :disabled="tab.isPristine"
            fab
            small
            color="warning"
            @click.stop="confirmRevert = true"
          >
            <icon-tooltip
              icon-name="i.Undo"
              :tooltip-text="$t('t.Cancel')"
            />
          </v-btn>
        </div>
        <div
          class="delete"
          v-if="!tab.isNew && showDelete && !isDeleted"
        >
          <v-btn
            fab
            small
            color="error"
            @click.stop="confirmDelete = true"
          >
            <icon-tooltip
              icon-name="i.Delete"
              :tooltip-text="$t('t.Delete')"
            />
          </v-btn>
        </div>
        <div
          class="close"
          v-if="showClose && tab.close"
        >
          <v-btn
            fab
            small
            @click.stop="close"
          >
            <v-icon>{{$icon('i.Close')}}</v-icon>
          </v-btn>
        </div>
        <div
          class="attachments"
          v-if="showLinkManager"
        >
          <link-manager
            :selection.sync="internalLinks"
            :linkTarget="linkTarget"
            v-on="$listeners"
            ref="link-manager"
          />
        </div>
        <div
          class="attachments"
          v-if="showFileManager"
        >
          <file-manager
            :queryConfig="queryConfig"
            :selection.sync="internalFiles"
            :readonly="!tab.canUpdate || readonlyFileManager"
            :hardDelete="hardDeleteFile"
            ref="file-manager"
          />
        </div>
        <div
          class="comment"
          v-if="comment"
        >
          <v-btn
            fab
            small
            @click.stop="clickOnComment"
          >
            <v-badge
              :value="hasComment"
              color="accent"
              dot
            >
              <icon-tooltip
                icon-name="i.Comment"
                :tooltip-text="$t('t.Comment')"
                ref-icon="comment"
              />
            </v-badge>
          </v-btn>
        </div>
        <slot />
        <v-spacer />
        <label-picker
          v-if="showLabels"
          class="mr-4"
          :selection.sync="computedLabels"
          :attach="false"
        />
      </div>
      <div class="d-flex gap">
        <div v-if="!noHistory">
          <history-menu :tab="tab" />
        </div>
        <icon-tooltip
          v-if="isDeleted"
          class="mr-2"
          color="error"
          :icon-name="'i.ExclamationThick'"
          :tooltip-text="$t('t.DocumentIsDeleted')"
        />
        <slot name="append" />
        <div v-if="newDocuments || specialActions">
          <v-menu
            offset-y
            nudge-bottom="2"
            left
            transition="scale-transition"
            origin="right top"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                small
                v-on="on"
              >
                <icon-tooltip
                  :icon-name="'i.DotsVertical'"
                  :tooltip-text="$t('t.Actions')"
                />
              </v-btn>
            </template>
            <v-card>
              <v-list>
                <new-document-items
                  v-if="newDocuments"
                  :documentTabs="newDocuments"
                />
                <v-divider v-if="newDocuments && specialActions" />
                <div
                  v-for="(action, index) in specialActions"
                  :key="index"
                >
                  <v-list-item
                    link
                    v-if="action.text"
                    @click="action.callback()"
                  >
                    <v-icon
                      v-if="action.icon"
                      class="mr-2"
                    >{{action.icon}}</v-icon>
                    <v-list-item-content>
                      <span class="nowrap">{{action.text}}</span>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider v-else />
                </div>
              </v-list>
            </v-card>
          </v-menu>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="confirmRevert"
      max-width=500
      @click:outside="cancelRevert"
    >
      <v-card>
        <v-card-title>{{$t('t.PromptConfirmation')}}</v-card-title>
        <v-card-text>
          <span class="mr-1">{{truncateWithEllipsis(tab.number || tab.title)}}</span>
          <span>{{$t('t.RevertConfirmation')}}</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="doRevert">{{$t('t.Yes')}}</v-btn>
          <v-btn
            color="primary"
            @click="cancelRevert"
          >{{$t('t.No')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmDelete"
      max-width=500
      @click:outside="confirmDelete = false"
    >
      <v-card>
        <v-card-title>{{$t('t.PromptConfirmation')}}</v-card-title>
        <v-card-text>
          <span class="mr-1">{{$t('t.DeleteConfirmation', {title: truncateWithEllipsis(tab.number)})}}</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="doDelete()">{{$t('t.Yes')}}</v-btn>
          <v-btn
            color="primary"
            @click="confirmDelete = false"
          >{{$t('t.No')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import recentsController from '../recentsController'
import Search from '@/pages/search/controllers'

export default {
  components: {
    IconTooltip: () => import('@/components/icon-tooltip'),
    FileManager: () => import('@/components/file-manager'),
    HistoryMenu: () => import('@/components/history-menu'),
    LabelPicker: () => import('@/components/label-picker'),
    LinkManager: () => import('@/components/link-manager'),
    NewDocumentItems: () => import('@/components/new-document-items')
  },
  activated () {
    this.createSearch()
  },
  deactivated () {
    this.search = undefined
  },
  mounted () {
    this.createSearch()
  },
  data () {
    return {
      confirmRevert: false,
      confirmDelete: false,
      dataLabels: [],
      revertThenClose: false,
      saveInProgress: false,
      search: null
    }
  },
  computed: {
    computedWarningSave () {
      return this.warningSave ? this.warningSave : null
    },
    internalFiles: {
      get () {
        return this.files
      },
      set (files) {
        this.$emit('update:files', files)
      }
    },
    internalLinks: {
      get () {
        return this.links
      },
      set (links) {
        this.$emit('update:files', links)
      }
    },
    computedLabels: {
      get () {
        return this.dataLabels
      },
      set (labels) {
        if (!this.lodash.isEqual(this.dataLabels, labels)) {
          this.dataLabels = this.lodash.cloneDeep(labels)
          this.search
            .executeWithJSON({ target: { labelIds: this.dataLabels } })
        }
      }
    },
    linkTarget () {
      if (!!this.tab.documentId && !!this.tab.documentType) {
        return { id: this.tab.documentId, type: this.tab.documentType }
      }
      return null
    }
  },
  methods: {
    async createSearch () {
      const documentType = this.tab.documentType?.replace('account-infos', 'accounts')
      const id = this.isNumeric(this.tab.documentId) ? Number(this.tab.documentId) : this.tab.documentId
      this.search = new Search(Search.actions.SET_LABELS_LINK)
        .chain(s => s.searchedIds.include([{ id: id, type: documentType }]))
        .chain(s => s.searchedDocumentTypes.include([documentType]))
    },
    cancelRevert () {
      this.confirmRevert = false
      this.revertThenClose = false
    },
    clickOnComment () {
      this.$emit('document-comment', { tab: this.tab, value: !this.tab.showComment })
    },
    close () {
      if (this.revert && !this.tab.isPristine) {
        this.revertThenClose = true
        this.confirmRevert = true
      } else {
        this.tab.close()
      }
    },
    async doDelete () {
      await this.deleteDocument()
      this.confirmDelete = false
      if (!this.noDeleteToast) {
        this.$store.dispatch('showWarningSnackbar', this.$t('t.Snackbar.DeleteConfirmation'))
      }
    },
    async doRevert () {
      await this.revert()
      this.$store.dispatch('showWarningSnackbar', this.$t('t.Snackbar.ChangeRevertedConfirmation'))
      if (this.revertThenClose) {
        this.tab.close()
      }
      this.cancelRevert()
    },
    async doSave () {
      if (this.saveInProgress) { return }

      this.saveInProgress = true

      await this.save().then(async () => {
        if (this.$refs['file-manager']) {
          await this.$refs['file-manager'].applyDeletion()
        }

        this.$store.dispatch('showSuccessSnackbar', this.successMessage ?? this.$t('t.Snackbar.SaveConfirmation'))
        this.$emit('document-reset', this.tab)
        this.$emit('document-saved', this.tab)

        if (this.revertAfterSave && this.revert) {
          await this.revert()
        } else if (this.closeAfterSave) {
          recentsController.close(this.tab)
        } else {
          this.$emit('document-ready', this.tab)
        }
      }).catch(e => {
        let errorMessage = e.response?.data?.message?.substring(0, 2) === 't.' ? this.$t(e.response.data.message) : e.response?.data?.message
        if (!errorMessage && e.response?.data?.conflictCultureNames?.length) {
          errorMessage = this.$t('t.ConflictCultureNames', { cultures: e.response.data.conflictCultureNames.join(', ') })
        }
        this.$store.dispatch('showErrorSnackbar', errorMessage ?? this.$t('t.Snackbar.SaveError'))
        console.trace(e.response?.data)
        console.trace(e)
      }).finally(() => {
        this.saveInProgress = false
      })
    },
    historyQuery (event) {
      if (event) {
        this.$refs?.history?.query()
      } else {
        this.$refs?.history?.clear()
      }
    }
  },
  watch: {
    labels: {
      handler (v) {
        if (!this.lodash.isEqual(v, this.dataLabels)) {
          this.dataLabels = v ? this.lodash.cloneDeep(v) : []
        }
      },
      immediate: true
    }
  },
  props: {
    showSave: {
      type: Boolean,
      default: true
    },
    showCancel: {
      type: Boolean,
      default: true
    },
    closeAfterSave: Boolean,
    comment: Boolean,
    isDeleted: Boolean,
    deleteDocument: Function,
    files: [String, Array],
    hardDeleteFile: Boolean,
    hasComment: Boolean,
    links: Array,
    labels: Array,
    newDocuments: Array,
    noDeleteToast: Boolean,
    noHistory: Boolean,
    queryConfig: Object,
    readonlyFileManager: Boolean,
    readonlyLinkManager: Boolean,
    revert: Function,
    revertAfterSave: Boolean,
    save: Function,
    showClose: Boolean,
    showDelete: Boolean,
    showFileManager: Boolean,
    showLinkManager: Boolean,
    showLabels: Boolean,
    specialActions: Array,
    successMessage: String,
    tab: Object,
    warningSave: String
  }
}
</script>

<style lang="stylus" scoped>
@keyframes spin
  from
    transform rotate(0deg)

  to
    transform rotate(360deg)

.spin
  animation-duration 750ms
  animation-iteration-count infinite
  animation-name spin

.document-toolbar
  min-height 48px

.gap
  gap 12px

.history-card
  max-height calc(100vh - 192px)
  width 500px
  overflow-y auto
  overflow-x hidden
</style>
